import React from 'react'
import tw from 'twin.macro';
import styled from "styled-components";

import { Link, useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout, {Container, ContentWithPaddingXl} from '@components/Layout';
import Header from '@components/Header';
import CookieBanner from '@components/Cookies/CookieBanner';
import PostPreview from '@components/Post/PostPreview';
import BannerCharityComponent from '@components/charity/banner/BannerCharityComponent';
import FeatureCharityComponent from '@components/charity/feature/FeatureCharityComponent';
import CharityStep01Img from "@assets/images/app/charity/step01.png";
import CharityStep02Img from "@assets/images/app/charity/step02.png";
import CharityStep03Img from "@assets/images/app/charity/step03.png";
import CharityStep04Img from "@assets/images/app/charity/step04.png";
import AnimationRevealPage from '../helpers/AnimationRevealPage';


const Hero = tw.div`flex justify-center items-center text-xl`;
const SectionContainerBackground= styled.section`background-color:red`;
const SectionContainer= tw(SectionContainerBackground)`p-0 md:px-24 md:mt-32 pt-20 bg-gray-100`;
const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;

const Charity = props => {


    return (
        <Layout>
            <AnimationRevealPage>
            <Header />
            <SectionContainer id="charity">
                <div>
                <BannerCharityComponent></BannerCharityComponent>
                </div>
            </SectionContainer>     

            <FeatureCharityComponent
                subheading={<Subheading>1.</Subheading>}
                heading={
                <>
                    Busca el <HighlightedText>sello solidario</HighlightedText> en los bares y restaurantes del distrito
                </>
                }
                description="Cualquier consumición puede ser solidaria, solo tienes que indicar la cantidad que quieres aportar."
                imageSrc={CharityStep01Img}
                showDecoratorBlob={false}></FeatureCharityComponent> 


            <FeatureCharityComponent
                subheading={<Subheading>2.</Subheading>}
                textOnLeft={false}
                heading={
                <>
                    Registra tu <HighlightedText>donación</HighlightedText>
                </>
                }
                description="En el momento de pagar indica al camarero que quieres realizar una donación. Por ejemplo si has consumido un café que cuesta 1.60€ puedes añadir 0.40€ para pagar 2.00€ en total. Esos céntimos se transfiran a la ONG para apoyar las acciones solidarias en curso"
                imageSrc={CharityStep02Img}
                showDecoratorBlob={false}></FeatureCharityComponent>                 
            <CookieBanner/>

            <FeatureCharityComponent
                subheading={<Subheading>3.</Subheading>}
                heading={
                <>
                    Confirmación <HighlightedText>de la donación</HighlightedText>
                </>
                }
                description="Muestra la cantidad a donar al camarero en la APP para que incremente el valor total a pagar."
                imageSrc={CharityStep03Img}
                showDecoratorBlob={false}></FeatureCharityComponent>             

            <FeatureCharityComponent
                subheading={<Subheading>4.</Subheading>}
                textOnLeft={false}                
                heading={
                <>
                    Podrás ver la <HighlightedText>evolución</HighlightedText> de los proyectos en curso.
                </>
                }
                description="Podrás seguir cómo entre todos colaboramos con las acciones solidarias de AVANZA ONG y tener un control de todas las donaciones realizadas por tí."
                imageSrc={CharityStep04Img}
                showDecoratorBlob={false}></FeatureCharityComponent>     
            </AnimationRevealPage>            
        </Layout>
    );
}

export default Charity;