import React from 'react';
import BannerCharityContainer from './bannerCharity.style';
import CharityBannerImg from '@assets/images/app/charity/people.svg';
import CharitySealImg from '@assets/images/app/charity/seal.svg';
import BannerPinkImg from '@assets/images/banner-pink.svg';


const BannerCharityComponent = () => {
  

  return (
    <BannerCharityContainer>
        <div className="site-blocks-cover">
            <div className="container">
                <div className="row align-items-center justify-content-center">

                    <div className="col-md-12" style={{position: 'relative'}} data-aos="fade-up">
                        <img src={BannerPinkImg}  className="img-fluid img-absolute-bg"/>
                        <img src={CharityBannerImg} alt="sello solidario" className="d-none d-md-block img-fluid img-absolute"/>

                        <div className="row mb-4">
                            <div className="col-lg-6 mr-auto">
                                <img src={CharitySealImg} className="seal"/>
                                <p className="mt-5 seal-presentation">En colaboración con <b className="seal-presentation-highlight">avanzaONG</b> apoyamos <b className="seal-presentation-highlight">proyectos solidarios de cercanía</b>.</p>
                                <p className="mb-5 seal-description">Tú puedes ser parte activa, podrás donar la cantidad que decidas a través de cualquier establecimiento del barrio donde veas el sello solidario.</p> 
                            </div>                        
                        </div>

                    </div>
                </div>
            </div>
        </div>  


    </BannerCharityContainer>
  );
};

export default BannerCharityComponent;