import styled from 'styled-components';

const BannerCharityContainer = styled.div`

.seal-presentation {
    font-size: 1.3em;
}

.seal-presentation-highlight {
    font-size: 1.5em;
}

.seal-description {
    font-size: 1.2em;
    line-height: 1.3em;
    color: 
}

.img-absolute {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(30%);
    -ms-transform: translateY(-50%) translateX(30%);
    transform: translateY(-50%) translateX(30%);
    max-height: 40vh;
}

.img-absolute-bg {
    position: fixed;
    bottom: 0;
    right: -150px;
    top: -100px;
    height: 80vh;
}

.btn.btn-primary {
    font-size: 16px;
    border-radius: 30px;
    padding: 10px 30px;    
    border-width: 2px;
    background: #00b1bb;
    border-color: #00b1bb;
    color: #fff;
    font-weight: 800;
    -webkit-box-shadow: 0 4px 20px -5px rgba(0, 210, 181, 0.4);
    box-shadow: 0 4px 20px -5px rgba(0, 210, 181, 0.4);

    &:hover {
        background: #00676d;
        border-color: #00676d;
    }
}

.seal {
    max-width: 150px;
}

@media screen and (max-width: 575px) {
    .seal {
        margin-right: auto;
        margin-left: auto;
    }

    .seal-presentation {
        font-size: 1.0em !important;
    }    

    .seal-presentation-highlight {
        font-size: 1.2em;
    }    
}


`

export default BannerCharityContainer;